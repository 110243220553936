/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  ActionIcon,
  AppShell,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  Group,
  Image,
  Indicator,
  NumberInput,
  Paper,
  Select,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useListState } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import {
  IconChevronLeft,
  IconDownload,
  IconPlus,
  IconQuestionMark,
  IconRobotFace,
  IconTrash,
  IconUsers,
} from "@tabler/icons-react";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"; // Added this line
import API from "../api/api";
import logoJustText from "../images/logo-just-text.svg";

export default function EditTeam() {
  const { t } = useTranslation(); // Added this line
  const data = {
    ...JSON.parse(window.sessionStorage.getItem("aspaskes.beg.data")),
  };
  const fn = window.sessionStorage.getItem("aspaskes.beg.fn");
  // if (data && ( !data.pool || !data.matches)) {
  //   notifications.show({
  //     title: t("dataLoadErrorTitle"),
  //     message: t("dataLoadErrorMessage"),
  //     color: "red",
  //   });
  //   window.location.hash = "/";
  // }

  const [players, playerHandlers] = useListState(
    data.pool ?? [
      { name: "", overall: 5, id: 1 },
      { name: "", overall: 5, id: 2 },
    ]
  );
  const [fileName, setFileName] = useState(fn ?? `aspaskes-data`);
  const [fileNameReadonly, setFileNameReadonly] = useState(true);
  const [matches, matchHandlers] = useListState(data.matches ?? []);
  const [values, setValues] = useState();
  const [createLoading, setCreateLoading] = useState(false);
  const [generateLoading, setGenerateLoading] = useState(false);
  const [canStart, setCanStart] = useState(false);
  const [username, setUsername] = useState("");
  const api = new API();

  useEffect(() => {
    // if (!data.pool) {
    //   window.location.hash = "/";
    // }
    // window.sessionStorage.removeItem("aspaskes.beg.fn");
    // window.sessionStorage.removeItem("aspaskes.beg.data");
  }, []);

  useEffect(() => {
    if (players.filter((player) => player.name !== "").length < 2) {
      setCanStart(false);
      matchHandlers.setState([]);
    } else {
      setCanStart(true);
    }
  }, [players]);

  function addPlayerHandler() {
    playerHandlers.append({ name: "", overall: 5, id: players.length + 1 });
  }

  function deletePlayerHandler(index) {
    const id = players[index].id;
    playerHandlers.remove(index);
    matchHandlers.remove(
      matches.findIndex((value) => value[0] === id || value[1] === id)
    );
  }

  async function formatData() {
    return new Promise((resolve) => {
      const tempPlayers = players.filter(
        (player) =>
          !(
            player.name.trim().length === 0 ||
            typeof player.overall === "undefined"
          )
      );
      const tempMatches = matches.filter(
        (match) => !(match[0] === -1 || match[1] === -1)
      );
      resolve({ pool: tempPlayers, matches: tempMatches });
    });
  }

  const optionsFilter = () => {
    const matchesIDs = [];
    matches.forEach((value) => {
      if (value[0] !== -1 || value[1] !== -1) {
        matchesIDs.push(`${value[0]}`);
        matchesIDs.push(`${value[1]}`);
      }
    });

    return players
      .filter(
        (player) => !matchesIDs.includes(`${player.id}`) && player.name !== ""
      )
      .map((player) => {
        return { label: player.name, value: `${player.id}` };
      });
  };

  function addMatchHandler() {
    matchHandlers.append([-1, -1]);
  }

  async function createSessionHandler() {
    formatData().then(async (data) => {
      if (!data) {
        return;
      }
      setCreateLoading(true);
      await api
        .createSession(username, data)
        .then((response) => {
          Cookies.set("jwt", response.data.token, { sameSite: "strict" });
          window.sessionStorage.setItem(
            "aspaskes.beg.data",
            JSON.stringify(values)
          );
          window.location.hash = "/playground/" + response.data.sessionUUID;
        })
        .catch((error) => {
          console.log(error);
          notifications.show({
            title: t("createSessionErrorTitle"),
            message: t("createSessionErrorMessage"),
            color: "red",
          });
        })
        .finally(() => {
          setCreateLoading(false);
        });
    });
  }

  async function generateTeamHandler() {
    formatData().then((data) => {
      if (!data) {
        return;
      }
      setGenerateLoading(true);
      window.sessionStorage.setItem("aspaskes.beg.data", JSON.stringify(data));
      window.location.hash = "#generate";
    });
  }
  return (
    <AppShell
      header={{ height: 60 }}
      padding="md"
      withBorder={false}
    >
      {/* <AppShell.Footer>
        <Paper component={Center}>
          <Text fs={"italic"} c={"dimmed"}>
            <Trans
              i18nKey="designedAndDeveloped"
              components={[
                <a href="https://tscburak.dev" style={{ color: "black" }} />,
              ]}
            />
          </Text>
        </Paper>
      </AppShell.Footer> */}
      <AppShell.Header zIndex={40}>
        <Paper component={Center} h={"100%"} p={"sm"} shadow="sm">
          <Image w={150} src={logoJustText} />
        </Paper>
      </AppShell.Header>
      <AppShell.Main>
        <Stack p={"xl"} gap={"xl"}>
          <Center>
            <Stack w={"500px"}>
              <Flex align={"end"} justify={"space-between"}>
                {/* <Title order={3}>{t("reviewTeamDataTitle")}</Title> */}
                <TextInput
                  onChange={(event) => setFileName(event.target.value)}
                  size="sm"
                  label={t("fileName")}
                  fw={700}
                  styles={{ input: { paddingLeft: fileNameReadonly ? 0 : 5 } }}
                  variant={fileNameReadonly ? "unstyled" : "default"}
                  onClick={() => setFileNameReadonly(false)}
                  onBlur={(event) => {
                    setFileNameReadonly(true);
                    if (event.target.value === "") {
                      setFileName(fn ?? `${data.username}-aspaskes-data`);
                    }
                  }}
                  value={fileName}
                ></TextInput>
                <ActionIcon
                  disabled={!canStart}
                  onClick={() => {
                    const fileData = JSON.stringify({
                      pool: players,
                      matches: matches,
                    });
                    const blob = new Blob([fileData], { type: "text/plain" });
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.download = `${fileName}.json`;
                    link.href = url;
                    link.click();
                  }}
                >
                  <IconDownload />
                </ActionIcon>
              </Flex>
              <Divider />
              <Group gap={5}>
                <Text fw={600} size="sm">
                  {t("playersTitle")}
                </Text>
                <Tooltip
                  multiline
                  maw={400}
                  label={t("playersDescription")}
                  events={{ hover: true, focus: true, touch: true }}
                >
                  <ActionIcon
                    size={"xs"}
                    radius={"xl"}
                    variant="outline"
                    color="gray"
                  >
                    <IconQuestionMark />
                  </ActionIcon>
                </Tooltip>
              </Group>
              <Flex direction="column" gap={{ base: "xl", xs: "md" }}>
                {players.map((value, index) => (
                  <Group
                    wrap="nowrap"
                    key={index}
                    align="center"
                    justify="center"
                    w={"100%"}
                  >
                    <Grid gutter={{ base: "xs", sm: "md" }} w={"100%"}>
                      <Grid.Col span={8}>
                        <TextInput
                          onChange={(event) => {
                            playerHandlers.setItemProp(
                              index,
                              "name",
                              event.target.value
                            );
                          }}
                          value={value.name}
                          placeholder={t("playerNamePlaceholder")}
                        ></TextInput>
                      </Grid.Col>
                      <Grid.Col span={4}>
                        <Group wrap="nowrap">
                          <NumberInput
                            styles={{
                              input: {
                                color: `var(--mantine-color-APKSOverallColors-${
                                  (value.overall ?? 5) - 1
                                })`,
                                fontWeight: 500,
                              },
                            }}
                            onChange={(event) => {
                              playerHandlers.setItemProp(
                                index,
                                "overall",
                                event
                              );
                            }}
                            placeholder={t("overall")}
                            value={value.overall}
                            max={10}
                            min={1}
                          ></NumberInput>
                        </Group>
                      </Grid.Col>
                    </Grid>
                    {players.length > 2 && (
                      <Center>
                        <ActionIcon
                          onClick={() => {
                            deletePlayerHandler(index);
                          }}
                          color="red"
                        >
                          <IconTrash></IconTrash>
                        </ActionIcon>
                      </Center>
                    )}
                  </Group>
                ))}
                <Center>
                  <Button
                    fullWidth
                    variant="light"
                    leftSection={<IconPlus />}
                    onClick={addPlayerHandler}
                    color="APKSOrange"
                  >
                    {t("addPlayer")}
                  </Button>
                </Center>
              </Flex>
            </Stack>
          </Center>
          <Center>
            {canStart && (
              <Stack w={"500px"}>
                <Group gap={5}>
                  <Text fw={600} size="sm">
                    {t("matchesTitle")}
                  </Text>
                  <Tooltip
                    multiline
                    maw={400}
                    label={t("matchesDescription")}
                    events={{ hover: true, focus: true, touch: true }}
                  >
                    <ActionIcon
                      size={"xs"}
                      radius={"xl"}
                      variant="outline"
                      color="gray"
                    >
                      <IconQuestionMark />
                    </ActionIcon>
                  </Tooltip>
                </Group>
                <Flex direction="column" gap={{ base: "xl", xs: "md" }}>
                  {matches.map((value, index) => (
                    <Group
                      wrap="nowrap"
                      key={value[0] + "_" + value[1] + "_" + index}
                      align="center"
                      justify="center"
                      w={"100%"}
                    >
                      <Grid
                        gutter={{ base: "xs", sm: "md" }}
                        key={"matches_" + index}
                        w={"100%"}
                      >
                        <Grid.Col span={{ base: 12, xs: 6 }}>
                          <Select
                            placeholder={t("player1")}
                            filter={optionsFilter}
                            data={players.map((player) => {
                              return {
                                label: player.name,
                                value: `${player.id}`,
                              };
                            })}
                            onChange={(event) => {
                              matchHandlers.setItemProp(
                                index,
                                "0",
                                Number(event)
                              );
                            }}
                            value={`${value[0]}`}
                          ></Select>
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, xs: 6 }}>
                          <Select
                            placeholder={t("player2")}
                            filter={optionsFilter}
                            data={players.map((player) => {
                              return {
                                label: player.name,
                                value: `${player.id}`,
                              };
                            })}
                            onChange={(event) => {
                              matchHandlers.setItemProp(
                                index,
                                "1",
                                Number(event)
                              );
                            }}
                            value={`${value[1]}`}
                          ></Select>
                        </Grid.Col>
                      </Grid>
                      {matches.length > 0 && (
                        <Center>
                          <ActionIcon
                            onClick={() => {
                              matchHandlers.remove(index);
                            }}
                            color="red"
                          >
                            <IconTrash></IconTrash>
                          </ActionIcon>
                        </Center>
                      )}
                    </Group>
                  ))}
                  {matches.length <= Math.floor(players.length / 2) - 1 && (
                    <Center>
                      <Button
                        fullWidth
                        variant="light"
                        leftSection={<IconPlus />}
                        onClick={addMatchHandler}
                        color="APKSOrange"
                      >
                        {t("addMatch")}
                      </Button>
                    </Center>
                  )}
                </Flex>
              </Stack>
            )}
          </Center>
          <Center pt={"xl"}>
            <Stack w={"500px"}>
              <Grid align="end">
                <Grid.Col order={{ base: 3, sm: 1 }} span={{ base: 12, sm: 3 }}>
                  <Button
                    fullWidth
                    leftSection={<IconChevronLeft />}
                    onClick={() => {
                      window.location.hash = "";
                    }}
                    variant={"outline"}
                  >
                    {t("backButton")}
                  </Button>
                </Grid.Col>
                <Grid.Col order={{ base: 1, sm: 2 }} span={{ base: 12, sm: 9 }}>
                  <Stack gap={5}>
                    <TextInput
                      withAsterisk
                      value={username}
                      onChange={(event) => setUsername(event.target.value)}
                      accept=".json"
                      placeholder={t("enterUsername")}
                    />
                    <Button
                      leftSection={<IconUsers />}
                      disabled={!(canStart && username.trim().length > 0)}
                      loading={createLoading}
                      onClick={createSessionHandler}
                    >
                      {t("createSessionButton")}
                    </Button>
                  </Stack>
                </Grid.Col>
                <Grid.Col order={{ base: 2, sm: 3 }} span={12}>
                  <Indicator label={t("beta")} size={16}>
                    <Button
                      fullWidth
                      variant="gradient"
                      leftSection={<IconRobotFace />}
                      disabled={!canStart}
                      loading={generateLoading}
                      onClick={generateTeamHandler}
                    >
                      {t("generateByComputer")}
                    </Button>
                  </Indicator>
                </Grid.Col>
              </Grid>
            </Stack>
          </Center>
        </Stack>
      </AppShell.Main>
    </AppShell>
  );
}
