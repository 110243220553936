import {
  ActionIcon,
  Button,
  Center,
  Flex,
  Grid,
  Group,
  Modal,
  MultiSelect,
  Select,
  Stack,
  Stepper,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useDisclosure, useListState } from "@mantine/hooks";
import { IconDownload, IconPlus, IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const basePositions = [
  { group: "GOALKEEPER", items: ["GK"] },
  { group: "DEFENDER", items: ["CB", "LB", "RB"] },
  { group: "MIDFIELDER", items: ["CM"] },
  { group: "FORWARD", items: ["ST"] },
];

export default function CreateTeam() {
  const [active, setActive] = useState(0);
  const [players, playerHandlers] = useListState([
    {
      name: "",
      positions: [],
      id: 1,
    },
    {
      name: "",
      positions: [],
      id: 2,
    },
  ]);
  const [matches, matchHandlers] = useListState([]);
  const [opened, { open, close }] = useDisclosure(false);
  const { t } = useTranslation();

  const nextStep = () => {
    if (active === 0) {
      playerHandlers.filter((player) => player.name !== "");
    } else if (active === 1) {
      matchHandlers.filter((value) => value[0] !== -1 || value[1] !== -1);
    }
    setActive((current) => (current < 3 ? current + 1 : current));
  };

  const prevStep = () => {
    setActive((current) => (current > 0 ? current - 1 : current));
  };

  const optionsFilter = () => {
    const matchesIDs = [];
    matches.forEach((value) => {
      if (value[0] !== -1 || value[1] !== -1) {
        matchesIDs.push(`${value[0]}`);
        matchesIDs.push(`${value[1]}`);
      }
    });
    return players
      .filter((player) => !matchesIDs.includes(`${player.id}`))
      .map((player) => {
        return { label: player.name, value: `${player.id}` };
      });
  };

  function addPlayerHandler() {
    playerHandlers.append({ name: "", positions: [], id: players.length + 1 });
  }

  function deletePlayerHandler(index) {
    const id = players[index].id;
    playerHandlers.remove(index);
    console.log(
      matches.findIndex((value) => value[0].id === id || value[1].id === id)
    );
    matchHandlers.remove(
      matches.findIndex((value) => value[0] === id || value[1] === id)
    );
  }

  function addMatchHandler() {
    matchHandlers.append([-1, -1]);
  }

  return (
    <>
      <Center p={"xl"} pb={0}>
        <Stepper
          w={"500px"}
          active={active}
          onStepClick={setActive}
          allowNextStepsSelect={false}
        >
          <Stepper.Step
            label={t("firstStepLabel")}
            description={t("firstStepDescription")}
          >
            <Title pt={"sm"} order={3}>
              {t("addPlayersTitle")}
            </Title>
            <Text pb={"sm"} c={"dimmed"}>
              {t("addPlayersDescription")}
            </Text>
            <Flex
              direction={"column"}
              gap={{ base: "xl", xs: "md" }}
              w={"100%"}
              pt={"md"}
            >
              {players.map((value, index) => (
                <Group
                  wrap="nowrap"
                  key={index}
                  align="center"
                  justify="center"
                  w={"100%"}
                >
                  <Grid w={"100%"}>
                    <Grid.Col span={{ base: 12, xs: 6 }}>
                      <TextInput
                        onChange={(event) => {
                          playerHandlers.setItemProp(
                            index,
                            "name",
                            event.target.value
                          );
                        }}
                        value={value.name}
                        placeholder={t("playerNamePlaceholder")}
                      ></TextInput>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, xs: 6 }}>
                      <MultiSelect
                        data={basePositions}
                        onChange={(event) => {
                          playerHandlers.setItemProp(
                            index,
                            "positions",
                            event
                          );
                        }}
                        value={value.positions}
                        placeholder={
                          value.positions.length === 0
                            ? t("positionsPlaceholder")
                            : ""
                        }
                        maxValues={3}
                      ></MultiSelect>
                    </Grid.Col>
                  </Grid>
                  {players.length > 2 && (
                    <Center>
                      <ActionIcon
                        onClick={() => {
                          deletePlayerHandler(index);
                        }}
                        color="red"
                      >
                        <IconTrash></IconTrash>
                      </ActionIcon>
                    </Center>
                  )}
                </Group>
              ))}
              <Center>
                <ActionIcon onClick={addPlayerHandler} color="APKSOrange">
                  <IconPlus />
                </ActionIcon>
              </Center>
            </Flex>
          </Stepper.Step>
          <Stepper.Step
            label={t("secondStepLabel")}
            description={t("secondStepDescription")}
          >
            <Title order={3}>{t("setMatchesTitle")}</Title>
            <Text c={"dimmed"}>{t("setMatchesDescription")}</Text>
            <Flex
              direction={"column"}
              gap={{ base: "xl", xs: "md" }}
              w={"100%"}
              pt={"md"}
            >
              {matches.map((value, index) => (
                <Group
                  wrap="nowrap"
                  key={value[0] + "_" + value[1] + "_" + index}
                  align="center"
                  justify="center"
                  w={"100%"}
                >
                  <Grid key={"matches_" + index} w={"100%"}>
                    <Grid.Col span={{ base: 12, xs: 6 }}>
                      <Select
                        placeholder={t("player1Placeholder")}
                        filter={optionsFilter}
                        data={players.map((player) => {
                          return { label: player.name, value: `${player.id}` };
                        })}
                        onChange={(event) => {
                          matchHandlers.setItemProp(
                            index,
                            "0",
                            Number(event)
                          );
                        }}
                        value={`${value[0]}`}
                      ></Select>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, xs: 6 }}>
                      <Select
                        placeholder={t("player2Placeholder")}
                        filter={optionsFilter}
                        data={players.map((player) => {
                          return { label: player.name, value: `${player.id}` };
                        })}
                        onChange={(event) => {
                          matchHandlers.setItemProp(
                            index,
                            "1",
                            Number(event)
                          );
                        }}
                        value={`${value[1]}`}
                      ></Select>
                    </Grid.Col>
                  </Grid>
                  {matches.length > 0 && (
                    <Center>
                      <ActionIcon
                        onClick={() => {
                          matchHandlers.remove(index);
                        }}
                        color="red"
                      >
                        <IconTrash></IconTrash>
                      </ActionIcon>
                    </Center>
                  )}
                </Group>
              ))}
              <Center>
                <ActionIcon onClick={addMatchHandler} color="APKSOrange">
                  <IconPlus />
                </ActionIcon>
              </Center>
            </Flex>
          </Stepper.Step>
          <Stepper.Step
            label={t("finalStepLabel")}
            description={t("finalStepDescription")}
          >
            <Flex direction={"column"} gap={{ base: "xl", xs: "md" }} pt={"md"}>
              <Title order={5}>{t("playersTitle")}</Title>
              {players.map((value, index) => (
                <Grid key={"confirm_players_" + index} w={"100%"}>
                  <Grid.Col span={{ base: 12, xs: 6 }}>
                    <TextInput
                      readOnly
                      value={value.name}
                      placeholder={t("playerNamePlaceholder")}
                    ></TextInput>
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, xs: 6 }}>
                    <MultiSelect
                      readOnly
                      data={basePositions}
                      value={value.positions}
                      placeholder={
                        value.positions.length === 0
                          ? t("positionsPlaceholder")
                          : ""
                      }
                      rightSection={<></>}
                      maxValues={3}
                    ></MultiSelect>
                  </Grid.Col>
                </Grid>
              ))}
              {matches.length > 0 && (
                <Title pt={"md"} order={5}>
                  {t("matchesTitle")}
                </Title>
              )}
              {matches.map((value, index) => (
                <Grid key={"confirm_matches_" + index} w={"100%"}>
                  <Grid.Col span={{ base: 12, xs: 6 }}>
                    <Select
                      rightSection={<></>}
                      readOnly
                      data={players.map((player) => {
                        return { label: player.name, value: `${player.id}` };
                      })}
                      value={`${value[0]}`}
                    ></Select>
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, xs: 6 }}>
                    <Select
                      rightSection={<></>}
                      readOnly
                      data={players.map((player) => {
                        return { label: player.name, value: `${player.id}` };
                      })}
                      value={`${value[1]}`}
                    ></Select>
                  </Grid.Col>
                </Grid>
              ))}
            </Flex>
          </Stepper.Step>
          <Stepper.Completed>
            <Stack pt={"md"}>
              <Title order={3}>{t("downloadTitle")}</Title>
              <Text c={"dimmed"}>{t("downloadDescription")}</Text>
              <Button
                onClick={() => {
                  const fileData = JSON.stringify({
                    pool: players,
                    matches: matches,
                  });
                  const blob = new Blob([fileData], { type: "text/plain" });
                  const url = URL.createObjectURL(blob);
                  const link = document.createElement("a");
                  link.download = "aspaskes-team.json";
                  link.href = url;
                  link.click();
                }}
                leftSection={<IconDownload />}
              >
                {t("downloadButton")}
              </Button>
            </Stack>
          </Stepper.Completed>
        </Stepper>
      </Center>
      <Stack pb={"xl"} gap={"xl"} mt="xl" justify="center" align="center">
        <Group>
          <Button disabled={active === 0} variant="default" onClick={prevStep}>
            {t("backButton")}
          </Button>
          {active < 3 && (
            <Button
              disabled={
                active === 0 &&
                players.filter((player) => player.name !== "").length < 2
              }
              onClick={nextStep}
            >
              {active === 2 ? t("confirmButton") : t("nextStepButton")}
            </Button>
          )}
        </Group>
        <Button
          color={"APKSOrange"}
          onClick={() => {
            window.location.hash = "";
          }}
        >
          {t("goToHomePageButton")}
        </Button>
      </Stack>

      <Modal
        centered
        opened={opened}
        onClose={close}
        withCloseButton={false}
        title={
          <Text fw={700} pl={"sm"}>
            {t("confirmModalTitle")}
          </Text>
        }
      >
        <Stack gap={"md"} pl={"sm"} pb={"xs"}>
          {t("confirmModalMessage")}
          <Center>
            <Group>
              <Button
                onClick={() => {
                  prevStep();
                  close();
                }}
              >
                {t("confirmModalYes")}
              </Button>
              <Button
                variant="outline"
                onClick={() => {
                  close();
                }}
              >
                {t("confirmModalNo")}
              </Button>
            </Group>
          </Center>
        </Stack>
      </Modal>
    </>
  );
}
