/* eslint-disable jsx-a11y/anchor-has-content */
//TODO: send the turn to all users in ws
//TODO: manage after a match pick

/* eslint-disable react-hooks/exhaustive-deps */
import {
  ActionIcon,
  AppShell,
  Badge,
  Burger,
  Button,
  CopyButton,
  Flex,
  Grid,
  Group,
  Image,
  Indicator,
  LoadingOverlay,
  Menu,
  MenuDropdown,
  MenuItem,
  MenuLabel,
  MenuTarget,
  Modal,
  Paper,
  ScrollArea,
  Stack,
  Tabs,
  Text,
  Tooltip,
} from "@mantine/core";
import { useDisclosure, useListState } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import {
  IconDotsVertical,
  IconMessage,
  IconPlugConnectedX,
  IconShare2,
  IconSquareRoundedLetterA,
  IconSquareRoundedLetterB,
  IconSwitchHorizontal,
  IconUsersGroup,
} from "@tabler/icons-react";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useWebSocket from "react-use-websocket";
import ChatBox from "../components/chat-box";
import ExportView from "../components/export-view";
import PlayerCard from "../components/player-card";
import logoJustText from "../images/logo-just-text.svg";
import classes from "../styles/index.module.css";

const WS_URL =
//  "ws://localhost:8000";
"wss://api.aspaskes.tscburak.dev";

function Playground() {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    pool: [],
    teama: [],
    teamb: [],
    matches: [],
    turn: "",
  });
  const [selected, setSelected] = useState();
  const [opened, { toggle }] = useDisclosure();
  const [openedExport, { open, close }] = useDisclosure(false);
  const [users, setUsers] = useState([]);
  const [captains, setCaptains] = useState({ teama: "", teamb: "" });
  const [moderator, setModerator] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const { sessionUUID } = useParams();
  const INVITE_URL = `https://${window.location.host}/#join/${sessionUUID}`;
  const [status, setStatus] = useState();
  const [turn, setTurn] = useState();
  const [messages, handlers] = useListState([]);
  const [unreadMessageCounter, setUnreadMessageCounter] = useState(0);
  const [activeTab, setActiveTab] = useState("users");
  const { lastJsonMessage, sendJsonMessage, readyState } = useWebSocket(
    WS_URL + `/?sessionUUID=${sessionUUID}&token=${Cookies.get("jwt")}`,
    {
      onClose: (err) => {
        if (err.code === 1014) {
          notifications.show({
            title: t("invalidSessionTitle"),
            message: t("invalidSessionMessage"),
            autoClose: 5000,
            color: "red",
          });
          window.location.hash = "";
        } else if (err.code === 4401) {
          window.location.hash = `#join/${sessionUUID}`;
          Cookies.remove("jwt");
        } else if (err.code === 4000) {
          notifications.show({
            title: t("kickedByModTitle"),
            message: t("kickedByModDesc"),
            autoClose: 5000,
            color: "red",
          });
          Cookies.remove("jwt");
          window.location.hash = "";
        } else if (err.code === 1006) {
          notifications.show({
            title: t("internalErrorTitle"),
            message: t("internalErrorMessage"),
            autoClose: 5000,
            color: "red",
          });
          Cookies.remove("jwt");
          window.location.hash = "";
        }
      },
    }
  );

  function sendMessageHandler(message, type, event) {
    sendJsonMessage({
      message,
      type,
      event,
    });
  }

  useEffect(() => {
    if (lastJsonMessage !== null) {
      switch (lastJsonMessage.type) {
        case "datafetch":
          setValues({ ...values, ...lastJsonMessage.message.data });
          setStatus(lastJsonMessage.message.status);
          setTurn(lastJsonMessage.message.turn);
          setSelected();
          break;
        case "usersfetch":
          setUsers(lastJsonMessage.message);
          break;
        case "rolefetch":
          setModerator(lastJsonMessage.message.moderator ?? "");
          setCaptains({
            teama: lastJsonMessage.message.teama ?? "",
            teamb: lastJsonMessage.message.teamb ?? "",
          });
          break;
        case "mefetch":
          setUserInfo({ ...lastJsonMessage.message });
          break;
        case "sessionfetch":
          setStatus(lastJsonMessage.message.status);
          setTurn(lastJsonMessage.message.turn);
          break;
        case "message":
          if (activeTab !== "chat") {
            setUnreadMessageCounter(unreadMessageCounter + 1);
          }
          handlers.append({
            message: lastJsonMessage.message.message,
            createdAt: lastJsonMessage.message.createdAt,
            createdBy: lastJsonMessage.message.createdBy,
          });
          break;
        case "serverinfo":
          if (lastJsonMessage.event === "sessionend") {
            window.location.hash = "";
            notifications.show({
              title: t("endedByModTitle"),
              message: t("endedByModMessage"),
              autoClose: 5000,
            });
          }
          break;
        case "error":
          const error = lastJsonMessage.message;
          switch (error.status) {
            case 406:
              notifications.show({
                title: t("notProducedErrorTitle"),
                message: t("notProducedErrorDescription"),
                color: "red",
                autoClose: 10000,
              });
              break;

            default:
              break;
          }
          break;
        default:
          break;
      }
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    if (activeTab === "chat" && unreadMessageCounter > 0) {
      setUnreadMessageCounter(0);
    }
  }, [handlers]);
  function findByID(id, list) {
    return list.filter((element) => id === element.id)[0];
  }

  function removeByID(id, list) {
    return list.filter((element) => id !== element.id);
  }

  function moveToHandler(from, to, userID) {
    sendMessageHandler(
      {
        from,
        to,
        userID,
      },
      "datafetch",
      "move"
    );
  }

  function generateBadge(uuid) {
    const badges = [];
    if (uuid === moderator) {
      badges.push(
        <Badge key={`${uuid}_modBadge`} size="xs">
          {t("modBadge")}
        </Badge>
      );
    }
    if (uuid === captains.teama) {
      badges.push(
        <Badge key={`${uuid}_captainABadge`} size="xs">
          {t("captainABadge")}
        </Badge>
      );
    }
    if (uuid === captains.teamb) {
      badges.push(
        <Badge key={`${uuid}_captainBBadge`} size="xs">
          {t("captainBBadge")}
        </Badge>
      );
    }

    return (
      <Flex wrap={"nowrap"} gap={"xs"}>
        {badges}
      </Flex>
    );
  }

  function captainHandler(team, captainUUID) {
    // api.setCaptain(sessionUUID, team, captainUUID);
    sendMessageHandler({ captainUUID, team }, "rolefetch", "captain");
  }

  function pickHandler(id) {
    const item = findByID(id, values.pool);

    const tempValues = values;
    if (turn === "teama") {
      tempValues.teama.push(item);
    } else if (turn === "teamb") {
      tempValues.teamb.push(item);
    }
    tempValues.pool = removeByID(id, values.pool);
    sendMessageHandler(
      {
        data: {
          pool: tempValues.pool,
          teama: tempValues.teama,
          teamb: tempValues.teamb,
          matches: tempValues.matches,
        },
        turn,
      },
      "datafetch",
      "pick"
    );
    setSelected();
  }

  function matchHandler(order) {
    let tempValues = values;
    const targetID = tempValues.matches[0][order];
    const affectedID = tempValues.matches[0][(order + 1) % 2];
    if (turn === "teama") {
      tempValues.teama.push(findByID(targetID, values.pool));
      tempValues.teamb.push(findByID(affectedID, values.pool));
    } else if (turn === "teamb") {
      tempValues.teamb.push(findByID(targetID, values.pool));
      tempValues.teama.push(findByID(affectedID, values.pool));
    }
    tempValues.matches.shift();
    tempValues.pool = removeByID(targetID, values.pool);
    tempValues.pool = removeByID(affectedID, values.pool);
    sendMessageHandler(
      {
        data: {
          pool: tempValues.pool,
          teama: tempValues.teama,
          teamb: tempValues.teamb,
          matches: tempValues.matches,
        },
        turn,
      },
      "datafetch",
      "pick"
    );
    setSelected();
  }

  function chatHandler(message) {
    sendMessageHandler(
      {
        message,
      },
      "message",
      "message"
    );
  }

  function startSessionHandler() {
    sendMessageHandler(sessionUUID, "sessionfetch", "start");
  }

  async function leaveHandler() {
    // await api.leaveSession(sessionUUID);
    sendMessageHandler("leave", "disconnect", "leave");
    Cookies.remove("jwt");
    window.location.hash = "";
  }

  const openKickModal = (user) =>
    modals.openConfirmModal({
      title: t("confirmUserDisconnectTitle"),
      children: (
        <Text size="sm">
          {t("confirmUserDisconnectMessage", { username: user.username })}
        </Text>
      ),
      labels: { confirm: t("confirm"), cancel: t("cancel") },
      onCancel: () => {},
      onConfirm: () => sendMessageHandler(user.uuid, "disconnect", "kick"),
    });

  const openLeaveModal = () =>
    modals.openConfirmModal({
      title: t("confirmExitTitle"),
      children: <Text size="sm">{t("confirmExitMessage")}</Text>,
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => {},
      onConfirm: () => leaveHandler(),
    });

  return (
    <AppShell
      header={{ height: 60 }}
      aside={{ width: 300, breakpoint: "lg", collapsed: { mobile: !opened } }}
      padding="md"
      withBorder={false}
    >
      <LoadingOverlay
        visible={readyState !== 1}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      {/* <AppShell.Footer>
        <Paper component={Center}>
          <Text fs={"italic"} c={"dimmed"}>
            <Trans
              i18nKey="designedAndDeveloped"
              components={[
                <a href="https://tscburak.dev" style={{ color: "black" }} />,
              ]}
            />
          </Text>
        </Paper>
      </AppShell.Footer> */}
      <AppShell.Header zIndex={40}>
        <Paper component={Flex} p={"sm"} shadow="sm">
          <Flex align={"center"} w={"100%"}>
            {unreadMessageCounter > 0 && !opened ? (
              <Indicator
                label={unreadMessageCounter}
                size={16}
                color="APKSOrange"
                hiddenFrom="lg"
              >
                <Burger
                  opened={opened}
                  onClick={toggle}
                  hiddenFrom="lg"
                  size="sm"
                />
              </Indicator>
            ) : (
              <Burger
                opened={opened}
                onClick={toggle}
                hiddenFrom="lg"
                size="sm"
              />
            )}
            <Image mr={"auto"} ml={"auto"} w={150} src={logoJustText} />
            {/* <Popover position="bottom" withArrow shadow="md">
              <Popover.Target>
                <Tooltip label={t("invite")}>
                  <ActionIcon ml={"auto"}>
                    <IconUsersPlus />
                  </ActionIcon>
                </Tooltip>
              </Popover.Target>

              <Popover.Dropdown>
                <Group wrap="nowrap" p={"md"}>
                  <TextInput
                    width={"fit-content"}
                    value={INVITE_URL}
                    readOnly
                  ></TextInput>
                  <CopyButton value={INVITE_URL} timeout={2000}>
                    {({ copied, copy }) => (
                      <Tooltip
                        label={copied ? t("copied") : t("copy")}
                        withArrow
                        position="right"
                      >
                        <ActionIcon
                          color={copied ? "teal" : "gray"}
                          variant="subtle"
                          onClick={copy}
                        >
                          {copied ? <IconCheck /> : <IconCopy />}
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </CopyButton>
                </Group>
              </Popover.Dropdown>
            </Popover> */}
          </Flex>
        </Paper>
      </AppShell.Header>
      <AppShell.Aside zIndex={2} pb={"xl"}>
        <Paper component={Stack} p="md" h={"100%"} shadow="xl">
          <Tabs
            h={"100%"}
            pb={"calc(2*var(--mantine-spacing-lg))"}
            defaultValue="users"
            onChange={setActiveTab}
          >
            <Tabs.List>
              <Tabs.Tab value="users" leftSection={<IconUsersGroup />}>
                {t("users")}
              </Tabs.Tab>
              <Tabs.Tab
                value="chat"
                leftSection={
                  unreadMessageCounter > 0 ? (
                    <Indicator
                      label={unreadMessageCounter}
                      size={16}
                      color="APKSOrange"
                    >
                      <IconMessage />
                    </Indicator>
                  ) : (
                    <IconMessage />
                  )
                }
              >
                {t("chat")}
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel h={"100%"} value="chat">
              <ChatBox messages={messages} onSend={chatHandler} />
            </Tabs.Panel>

            <Tabs.Panel h={"100%"} value="users">
              <Stack pt={"sm"} h={"100%"}>
                <ScrollArea h={"100%"}>
                  <Stack gap={5}>
                    <CopyButton value={INVITE_URL}>
                      {({ copied, copy }) => (
                        <Button
                          color={copied ? "teal" : "APKSBlue"}
                          onClick={copy}
                        >
                          {copied ? t("copied") : t("invite")}
                        </Button>
                      )}
                    </CopyButton>
                    {users.map((user) => (
                      <Group
                        justify="space-between"
                        classNames={{ root: classes.usersListItem }}
                        p={"sm"}
                        key={`users_${user.uuid}`}
                      >
                        <Stack gap={5}>
                          <Text
                            c={
                              user.uuid === userInfo.userUUID
                                ? "APKSOrange.5"
                                : ""
                            }
                          >
                            {user.username}
                          </Text>
                          {generateBadge(user.uuid)}
                        </Stack>
                        {userInfo.userUUID === moderator ? (
                          <Menu position="bottom-end">
                            <Menu.Target>
                              <ActionIcon
                                variant="transparent"
                                color="APKSGray.9"
                              >
                                <IconDotsVertical />
                              </ActionIcon>
                            </Menu.Target>
                            <Menu.Dropdown
                              p={0}
                              styles={{
                                dropdown: {
                                  border: "none",
                                },
                              }}
                            >
                              <Paper withBorder shadow="xl" p={"xs"}>
                                <Flex gap={"xs"}>
                                  <Tooltip label={t("setTeamACaptain")}>
                                    <ActionIcon
                                      onClick={() => {
                                        captainHandler("teama", user.uuid);
                                      }}
                                      color={
                                        captains["teama"] === user.uuid
                                          ? "APKSBlue"
                                          : "gray"
                                      }
                                      variant="subtle"
                                    >
                                      <IconSquareRoundedLetterA />
                                    </ActionIcon>
                                  </Tooltip>
                                  <Tooltip label={t("setTeamBCaptain")}>
                                    <ActionIcon
                                      onClick={() => {
                                        captainHandler("teamb", user.uuid);
                                      }}
                                      color={
                                        captains["teamb"] === user.uuid
                                          ? "APKSBlue"
                                          : "gray"
                                      }
                                      variant="subtle"
                                    >
                                      <IconSquareRoundedLetterB />
                                    </ActionIcon>
                                  </Tooltip>
                                  {userInfo.userUUID !== user.uuid && (
                                    <Tooltip label={t("disconnect")}>
                                      <ActionIcon
                                        onClick={() => openKickModal(user)}
                                        color="red"
                                        variant="subtle"
                                      >
                                        <IconPlugConnectedX />
                                      </ActionIcon>
                                    </Tooltip>
                                  )}
                                </Flex>
                              </Paper>
                            </Menu.Dropdown>
                          </Menu>
                        ) : (
                          <></>
                        )}
                      </Group>
                    ))}
                  </Stack>
                </ScrollArea>
                <Button
                  onClick={openLeaveModal}
                  color="red"
                  mt={"auto"}
                  fullWidth
                >
                  {t("leaveSession")}
                </Button>
              </Stack>
            </Tabs.Panel>
          </Tabs>
        </Paper>
      </AppShell.Aside>
      <AppShell.Main>
        <Stack>
          <Grid
            pl={"xl"}
            pr={"xl"}
            pb={"xl"}
            justify="center"
            align="center"
          >
            <Grid.Col order={{ base: 2, sm: 0 }} span={{ base: 12, sm: status === "completed" ? 6 : 4 }}>
              <Stack
                c={turn === "teama" && status !== "completed" ? "APKSBlue" : ""}
                gap={0}
                pb={"xs"}
              >
                <Text ta={"center"} fw={700}>
                  {t("teamATitle")}
                </Text>
                {users.find((user) => user.uuid === captains["teama"]) ? (
                  <Text ta={"center"} size="sm">
                    {
                      users.find((user) => user.uuid === captains["teama"])
                        .username
                    }
                  </Text>
                ) : (
                  <Text ta={"center"} fs={"italic"} size="sm">
                    {t("noCaptainYet")}
                  </Text>
                )}
              </Stack>
              <Paper
                shadow="md"
                h={"500px"}
                p={"xl"}
                component={ScrollArea}
                withBorder
              >
                {values.teama.length > 0 ? (
                  values.teama.map((player) => (
                    <PlayerCard
                      left={
                        userInfo.userUUID === moderator && (
                          <Menu>
                            <MenuTarget>
                              <ActionIcon
                                onClick={(e) => e.stopPropagation()}
                                size={"sm"}
                                variant="outline"
                              >
                                <IconSwitchHorizontal />
                              </ActionIcon>
                            </MenuTarget>
                            <MenuDropdown>
                              <MenuLabel>
                                {t("moveTo", { username: player.name })}
                              </MenuLabel>
                              <MenuItem
                                onClick={(e) => {
                                  e.stopPropagation();
                                  moveToHandler("teama", "teamb", player.id);
                                }}
                              >
                                {t("teamBTitle")}
                              </MenuItem>
                              <MenuItem
                                onClick={(e) => {
                                  e.stopPropagation();
                                  moveToHandler("teama", "pool", player.id);
                                }}
                              >
                                {t("poolTitle")}
                              </MenuItem>
                            </MenuDropdown>
                          </Menu>
                        )
                      }
                      key={"teama_" + player.id}
                      props={player}
                    />
                  ))
                ) : (
                  <Text ta={"center"} c="dimmed" fs={"italic"} fw={100}>
                    {t("noPlayerSelected")}
                  </Text>
                )}
              </Paper>
            </Grid.Col>
            {status !== "completed" && (
              <Grid.Col order={{ base: 0, sm: 1 }} span={{ base: 12, sm: 4 }}>
                <Stack justify="center" align="center" gap={0} pb={"xs"}>
                  <Text ta={"center"} fw={700}>
                    {t("poolTitle")}
                  </Text>
                  <Badge>
                    {status === "not-started" && t("beReadyTitle")}
                    {status === "regular" && t("regularModeTitle")}
                    {status === "matches" && t("matchesModeTitle")}
                  </Badge>
                </Stack>
                <Paper
                  component={ScrollArea}
                  p={"xl"}
                  h={"500px"}
                  shadow="md"
                  withBorder
                >
                  {status === "matches" && values.matches.length > 0 && (
                    <>
                      <PlayerCard
                        direction={"vertical"}
                        onClick={() => setSelected(0)}
                        props={findByID(values.matches[0][0], values.pool)}
                        selected={0 === selected}
                      />
                      <PlayerCard
                        direction={"vertical"}
                        onClick={() => setSelected(1)}
                        props={findByID(values.matches[0][1], values.pool)}
                        selected={1 === selected}
                      />
                    </>
                  )}
                  {(status === "regular" || status === "not-started") &&
                    values.pool.map((player) => (
                      <PlayerCard
                        left={
                          userInfo.userUUID === moderator && (
                            <Menu>
                              <MenuTarget>
                                <ActionIcon
                                  onClick={(e) => e.stopPropagation()}
                                  size={"sm"}
                                  variant="outline"
                                >
                                  <IconSwitchHorizontal />
                                </ActionIcon>
                              </MenuTarget>
                              <MenuDropdown>
                                <MenuLabel>
                                  {t("moveTo", { username: player.name })}
                                </MenuLabel>
                                <MenuItem
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    moveToHandler("pool", "teama", player.id);
                                  }}
                                >
                                  {t("teamATitle")}
                                </MenuItem>
                                <MenuItem
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    moveToHandler("pool", "teamb", player.id);
                                  }}
                                >
                                  {t("teamBTitle")}
                                </MenuItem>
                              </MenuDropdown>
                            </Menu>
                          )
                        }
                        key={"pool_" + player.id}
                        onClick={() => setSelected(player.id)}
                        props={player}
                        selected={player.id === selected}
                      />
                    ))}
                </Paper>
              </Grid.Col>
            )}
            <Grid.Col order={{ base: 2, sm: 2 }} span={{ base: 12, sm: status === "completed" ? 6 : 4 }}>
              <Stack
                c={turn === "teamb" && status !== "completed" ? "APKSBlue" : ""}
                gap={0}
                pb={"xs"}
              >
                <Text ta={"center"} fw={700}>
                  {t("teamBTitle")}
                </Text>
                {users.find((user) => user.uuid === captains["teamb"]) ? (
                  <Text ta={"center"} size="sm">
                    {
                      users.find((user) => user.uuid === captains["teamb"])
                        .username
                    }
                  </Text>
                ) : (
                  <Text ta={"center"} fs={"italic"} size="sm">
                    {t("noCaptainYet")}
                  </Text>
                )}
              </Stack>
              <Paper
                component={ScrollArea}
                p={"xl"}
                h={"500px"}
                shadow="md"
                withBorder
              >
                {values.teamb.length > 0 ? (
                  values.teamb.map((player, index) => (
                    <PlayerCard
                      left={
                        userInfo.userUUID === moderator && (
                          <Menu>
                            <MenuTarget>
                              <ActionIcon
                                onClick={(e) => e.stopPropagation()}
                                size={"sm"}
                                variant="outline"
                              >
                                <IconSwitchHorizontal />
                              </ActionIcon>
                            </MenuTarget>
                            <MenuDropdown>
                              <MenuLabel>
                                {t("moveTo", { username: player.name })}
                              </MenuLabel>
                              <MenuItem
                                onClick={(e) => {
                                  e.stopPropagation();
                                  moveToHandler("teamb", "teama", player.id);
                                }}
                              >
                                {t("teamATitle")}
                              </MenuItem>
                              <MenuItem
                                onClick={(e) => {
                                  e.stopPropagation();
                                  moveToHandler("teamb", "pool", player.id);
                                }}
                              >
                                {t("poolTitle")}
                              </MenuItem>
                            </MenuDropdown>
                          </Menu>
                        )
                      }
                      key={"teamb_" + player.id}
                      index={index}
                      props={player}
                    />
                  ))
                ) : (
                  <Text ta={"center"} c="dimmed" fs={"italic"} fw={100}>
                    {t("noPlayerSelected")}
                  </Text>
                )}
              </Paper>
            </Grid.Col>
            <Grid.Col pb={{base:"xl", sm:0}} order={status === "completed" ? 2:{ base: 1, sm: 3 }} span={{ base: 12, sm: 4 }}>
              <Stack>
                <Flex gap={"sm"} justify={"center"} align={"center"}>
                  {(userInfo.userUUID === captains.teama ||
                    userInfo.userUUID === captains.teamb) &&
                    status !== "completed" && (
                      <Button
                        onClick={() => {
                          if (status === "regular") {
                            pickHandler(selected);
                          } else if (status === "matches") {
                            matchHandler(selected);
                          }
                        }}
                        color="APKSOrange"
                        fullWidth
                        disabled={
                          !(
                            typeof selected !== "undefined" &&
                            captains[turn] === userInfo.userUUID
                          )
                        }
                      >
                        {t("pick")}
                      </Button>
                    )}
                </Flex>
                {status === "completed" && (
                  <Button
                    onClick={() => {
                      open();
                    }}
                    color="APKSBlue"
                    fullWidth
                    leftSection={<IconShare2 />}
                  >
                    {t("export")}
                  </Button>
                )}
                {userInfo.userUUID === moderator &&
                  status === "not-started" && (
                    <Button
                      onClick={startSessionHandler}
                      color="APKSBlue"
                      fullWidth
                    >
                      {t("startTheSession")}
                    </Button>
                  )}
              </Stack>
            </Grid.Col>
          </Grid>
        </Stack>
        {/* <Stack pt={"sm"} justify="center" align="center">
          <Text>
            You are: <b>{userInfo.userUUID}</b>
          </Text>
          <Text>
            Moderator is: <b>{moderator}</b>
          </Text>
          <Text>
            Team A Captain is: <b>{captains.teama}</b>
          </Text>
          <Text>
            Team B Captain is: <b>{captains.teamb}</b>
          </Text>
          <Text>
            Turn: <b>{turn}</b>
          </Text>
          {captains[turn] === userInfo.userUUID ? (
            <Text>Your Turn</Text>
          ) : (
            <Text>Wait</Text>
          )}
        </Stack> */}
        <Modal
          opened={openedExport}
          onClose={close}
          title={t("export")}
          centered
        >
          <ExportView teama={values.teama} teamb={values.teamb} />
        </Modal>
      </AppShell.Main>
    </AppShell>
  );
}

export default Playground;
