/* eslint-disable jsx-a11y/anchor-has-content */
import {
  AppShell,
  Button,
  Center,
  Grid,
  Group,
  Image,
  LoadingOverlay,
  Modal,
  Paper,
  ScrollArea,
  Stack,
  Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronLeft, IconRefresh, IconShare2 } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import API from "../api/api";
import ExportView from "../components/export-view";
import PlayerCard from "../components/player-card";
import logoJustText from "../images/logo-just-text.svg";
import { notifications } from "@mantine/notifications";

export default function GenerateTeam() {
  const { t } = useTranslation();
  const initData = {
    ...JSON.parse(window.sessionStorage.getItem("aspaskes.beg.data")),
  };
  const [data, setData] = useState(initData);
  const [teams, setTeams] = useState();
  const [status, setStatus] = useState("INIT");
  const [openedExport, { open, close }] = useDisclosure(false);
  const api = new API();

  useEffect(() => {
    if (!data.pool) {
      window.location.hash = "";
      return;
    }
    // window.sessionStorage.removeItem("aspaskes.gen.data")
    console.log(data);
    fetchTeam();
  }, []);

  async function fetchTeam() {
    setStatus("LOADING");
    await api
      .generateTeam(data)
      .then((res) => {
        setTeams({
          teama: res.teams.teama.players,
          teamb: res.teams.teamb.players,
        });
        setStatus("REGULAR");
      })
      .catch((err) => {
        notifications.show({
          title: t("internalErrorTitle"),
          message: t("internalErrorMessage"),
          color: "red",
        });
      });
  }
  return (
    <AppShell header={{ height: 60 }} padding="md" withBorder={false}>
      <LoadingOverlay
        visible={status === "INIT"}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      {/* <AppShell.Footer>
        <Paper component={Center}>
          <Text fs={"italic"} c={"dimmed"}>
            <Trans
              i18nKey="designedAndDeveloped"
              components={[
                <a href="https://tscburak.dev" style={{ color: "black" }} />,
              ]}
            />
          </Text>
        </Paper>
      </AppShell.Footer> */}
      <AppShell.Header zIndex={40}>
        <Paper component={Center} h={"100%"} p={"sm"} shadow="sm">
          <Image w={150} src={logoJustText} />
        </Paper>
      </AppShell.Header>
      <AppShell.Main>
        <Stack>
          <Grid pl={"xl"} pr={"xl"} pb={"xl"} justify="center" align="center">
            <Grid.Col order={0} span={{ base: 12, xs: 6 }}>
              <Stack gap={0} pb={"xs"}>
                <Text ta={"center"} fw={700}>
                  {t("teamATitle")}
                </Text>
              </Stack>
              <Paper
                shadow="md"
                h={"500px"}
                p={"xl"}
                component={ScrollArea}
                withBorder
              >
                {teams?.teama?.length > 0 ? (
                  teams.teama.map((player) => (
                    <PlayerCard key={"teama_" + player.id} props={player} />
                  ))
                ) : (
                  <Text ta={"center"} c="dimmed" fs={"italic"} fw={100}>
                    {t("noPlayerSelected")}
                  </Text>
                )}
              </Paper>
            </Grid.Col>
            <Grid.Col order={{ base: 1, sm: 2 }} span={{ base: 12, xs: 6 }}>
              <Stack gap={0} pb={"xs"}>
                <Text ta={"center"} fw={700}>
                  {t("teamBTitle")}
                </Text>
              </Stack>
              <Paper
                component={ScrollArea}
                p={"xl"}
                h={"500px"}
                shadow="md"
                withBorder
              >
                {teams?.teamb?.length > 0 ? (
                  teams.teamb.map((player, index) => (
                    <PlayerCard
                      key={"teamb_" + player.id}
                      index={index}
                      props={player}
                    />
                  ))
                ) : (
                  <Text ta={"center"} c="dimmed" fs={"italic"} fw={100}>
                    {t("noPlayerSelected")}
                  </Text>
                )}
              </Paper>
            </Grid.Col>
          </Grid>
          <Grid align="center" justify="center" w={"100%"} pr={"xl"} pl={"xl"}>
            <Grid.Col span={{ base: 12, sm: 6, lg: 4 }}>
              <Grid>
                <Grid.Col order={{base:3,sm:1}} span={{base:12, sm:4}}>
                  <Button
                    fullWidth
                    leftSection={<IconChevronLeft />}
                    onClick={() => {
                      window.location.hash = "#edit-team";
                    }}
                    variant={"outline"}
                  >
                    {t("backButton")}
                  </Button>
                </Grid.Col>
                <Grid.Col order={{base:1,sm:2}} span={{base:12, sm:8}}>
                  <Button
                    fullWidth
                    color={"APKSOrange"}
                    loading={status === "LOADING"}
                    onClick={fetchTeam}
                    leftSection={<IconRefresh />}
                  >
                    {t("regenerate")}
                  </Button>
                </Grid.Col>
                <Grid.Col order={{base:2,sm:3}}>
                  <Button
                    onClick={() => {
                      open();
                    }}
                    color="APKSBlue"
                    fullWidth
                    leftSection={<IconShare2 />}
                  >
                    {t("export")}
                  </Button>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
        </Stack>
        <Modal
          opened={openedExport}
          onClose={close}
          title={t("export")}
          centered
        >
          <ExportView teama={teams?.teama} teamb={teams?.teamb} />
        </Modal>
      </AppShell.Main>
    </AppShell>
  );
}
