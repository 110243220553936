import {
  ActionIcon,
  Paper,
  ScrollArea,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { IconSend } from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";
export default function ChatBox({ messages, onSend }) {
  const [message, setMessage] = useState("");
  const viewport = useRef(null);

  useEffect(() => {
    viewport.current.scrollTo({
      top: viewport.current.scrollHeight,
      behavior: "smooth",
    });
  }, [messages]);
  function onSendWrapper() {
    if (message.trim().length > 0) {
      onSend(message);
      setMessage("");
    }
  }
  return (
    <Stack pt={"sm"} h={"100%"}>
      <Paper
        p={"md"}
        shadow="none"
        withBorder
        h={"100%"}
        component={ScrollArea}
        viewportRef={viewport}
      >
        <Stack>
          {(messages ?? []).map((element) => (
            <Stack gap={0} key={new Date(element.createdAt).getUTCMilliseconds()}>
              <Text fs={"italic"} size="xs" c={"dimmed"}>
                {new Date(element.createdAt).toLocaleString()}
              </Text>
              <Text
              w={230}
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <span style={{ fontWeight: 700 }}>{element.createdBy}</span>:{" "}
                {element.message}
              </Text>
            </Stack>
          ))}
        </Stack>
      </Paper>
      <TextInput
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            onSendWrapper();
          }
        }}
        value={message}
        onChange={(event) => setMessage(event.target.value)}
        rightSection={
          <ActionIcon
            disabled={message.trim().length === 0}
            onClick={onSendWrapper}
            variant="subtle"
          >
            <IconSend />
          </ActionIcon>
        }
      ></TextInput>
    </Stack>
  );
}
