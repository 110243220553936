import { CodeError } from "../error";

export default class API {
  baseURL =
    // "http://localhost:8000";
    "https://api.aspaskes.tscburak.dev";

  async joinSession(sessionUUID, username) {
    return await fetch(this.baseURL + "/join/?sessionUUID=" + sessionUUID, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
      }),
    }).then((res) => {
      if (res.ok) {
        return res.json();
      }
      throw new CodeError(res.statusText, res.status);
    });
  }

  async createSession(username, data) {
    return await fetch(this.baseURL + "/create-session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        data: data,
      }),
    }).then((res) => {
      if (res.ok) {
        return res.json();
      }
      throw new Error("Error");
    });
  }

  async generateTeam(data) {
    return await fetch(this.baseURL + "/generate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data,
      }),
    }).then((res) => {
      if (res.ok) {
        return res.json();
      }
      throw new CodeError(res.statusText, res.status);
    });
  }
}
