import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          username: "Username",
          enterUsername: "Enter a username",
          teamFile: "Team File",
          uploadTeamFile: "Upload your team file (.json)",
          captainsSelect: "Captains select the players.",
          computerSelect: "Computer selects the players.",
          next: "Next",
          haveNotCreatedTeam: "Haven't created a team yet?",
          createTeam: "Create Team",
          firstStepLabel: "First Step",
          firstStepDescription: "Add Players",
          playersTitle: "Players",
          playersDescription:
            "Add players below in order. You can edit your team before each session.",
          playerNamePlaceholder: "Player Name",
          positionsPlaceholder: "Positions",
          confirmModalTitle: "Are you sure?",
          confirmModalMessage:
            "If you go back, your match settings will be lost. Are you sure you want to go back?",
          confirmModalYes: "Yes",
          confirmModalNo: "No",
          backButton: "Back",
          nextStepButton: "Next Step",
          secondStepLabel: "Second Step",
          secondStepDescription: "Set Matches",
          matchesTitle: "Matches",
          matchesDescription:
            "Sometimes two players can cause a power imbalance. You may not want to put them on the same team. In this section, you can match two players. These two players cannot be on the same team when the teams are created. You can skip this part if you don't need it.",
          player1Placeholder: "Player 1",
          player2Placeholder: "Player 2",
          confirmButton: "Confirm",
          finalStepLabel: "Final Step",
          finalStepDescription: "Review",
          downloadTitle: "Download",
          downloadDescription:
            "You can download the team file (.JSON) from below. Please save this file to use for future sessions. You can edit the base file before each session.",
          downloadButton: "Download",
          goToHomePageButton: "Go to Home Page",
          reviewTeamDataTitle: "Review Your Team Data",
          createSessionButton: "Create Session",
          dataLoadErrorTitle: "Data could not be loaded.",
          dataLoadErrorMessage:
            "Please check your team data and try again.",
          createSessionErrorTitle: "Error creating session.",
          createSessionErrorMessage:
            "Please check your internet connection and try again.",
          joinButton: "Join",
          teamATitle: "Team A",
          teamBTitle: "Team B",
          copiedText: "Copied",
          copyAsText: "Copy as Text",
          invite: "Invite",
          copied: "Copied",
          copy: "Copy",
          confirm: "Confirm",
          cancel: "Cancel",
          leaveSession: "Leave Session",
          chat: "Chat",
          users: "Users",
          pick: "Pick",
          export: "Export",
          setTeamACaptain: "Set as Captain for Team A",
          setTeamBCaptain: "Set as Captain for Team B",
          disconnect: "Disconnect",
          confirmUserDisconnectTitle: "Confirm User Disconnection",
          confirmUserDisconnectMessage:
            "Are you sure you want to disconnect {{username}}?",
          confirmExitTitle: "Confirm Exit",
          confirmExitMessage: "Are you sure you want to leave the session?",
          regularModeTitle: "Teams Are Being Completed",
          matchesModeTitle: "Matches Are Being Selected",
          matchesModeDescription: {
            desc1: "Matches mode description line 1",
            desc2: "Matches mode description line 2",
          },
          yourTurnMessage: "{{username}}, it's your turn to pick as a captain.",
          yourTurnNoCaptain:
            "It's {{turn}}'s turn. A captain must be assigned to make player selections.",
          yourTurnErrorMessage: "An error occurred.",
          endedByModTitle: "Session Ended",
          endedByModMessage:
            "The session has been ended by the moderator. You will be redirected to the home page.",
          invalidSessionTitle: "Invalid Session",
          invalidSessionMessage: "The session is invalid or has ended.",
          kickedByModTitle: "You Have Been Kicked from the Session",
          kickedByModDesc: "You have been kicked from the session by the moderator.",
          internalErrorTitle: "Error",
          internalErrorMessage: "An error occurred.",
          beReadyTitle: "Session Has Not Started Yet",
          noCaptainYet: "No captain has been assigned yet",
          noPlayerSelected: "No player has been selected yet",
          moveTo: "Move {{username}} to ",
          modBadge: "Mod",
          captainABadge: "A (C)",
          captainBBadge: "B (C)",
          poolTitle: "Player Pool",
          startTheSession: "Start the Session",
          teamFileDescription:
            "The player file is a file that the computer or captains will make selections from when you start the session. This file contains players and relevant information. If you don't have a file you created before, you can leave this section blank and create your players in the next step.",
          player1: "Player 1",
          player2: "Player 2",
          fileName: "File name",
          notProducedErrorTitle: "Your Action Could Not Be Completed",
          notProducedErrorDescription:
            "The player pairing you want to move is included in the list of matching players. The transfer process cannot be completed without selecting matching players.",
          generateByComputer: "Generate by Computer",
          regenerate: "Regenerate",
          designedAndDeveloped: "Designed and developed by <0>tscburak</0>.",
          howItWorksTitle: "How It Works?",
          howItWorksDesc: [
            'With aspaskes, you can either create your teams by determining captains through online sessions, or you can create computer-assisted teams based on the players\' strength. If you already have a team file created via AsPasKes, you can upload that file; otherwise, leave the file area blank and click on the <0>Continue</0> button. In the upcoming screen, you can edit an existing team file if available, or create a new one and download it to your computer for future use.',
            'To create two teams from the players here, you can either utilize the <0>Generate by Computer</0> option or <0>Create Session</0> option.',
            "<0>Generate by Computer:</0> Aims to create a balanced team by utilizing the general power values you provided while creating your players.",
            "<0>Create Session:</0> Aims to create teams online by determining captains with friends."
          ],
          aspaskesSlug:"aspaskes - Step In, Pick Up, Play On!",
          languages:"Languages",
          optional: "Optional",
          nextWithoutFile: "Next Without a Team File",
          fileCannotResolvedTitle: "File Error",
          fileCannotResolvedDescription: "Team file cannot be resolved. Please make sure that you have provided the correct file.",
          addMatch: "Add New Match",
          addPlayer: "Add New Player",
        },
      },
      tr: {
        translation: {
          username: "Kullanıcı Adı",
          enterUsername: "Bir kullanıcı adı girin",
          teamFile: "Takım Dosyası",
          uploadTeamFile: "Takım dosyanızı yükleyin (.json)",
          captainsSelect: "Oyuncuları kaptanlar seçer.",
          computerSelect: "Oyuncuları bilgisayar seçer.",
          next: "Devam Et",
          haveNotCreatedTeam: "Henüz bir takım oluşturmadınız mı?",
          createTeam: "Takım Oluştur",
          firstStepLabel: "Birinci adım",
          firstStepDescription: "Oyuncuları ekle",
          playersTitle: "Oyuncular",
          playersDescription:
            "Aşağıya sırayla oyuncuları ekleyin. Her oturumdan önce takımınızı düzenleyebilirsiniz.",
          playerNamePlaceholder: "Oyuncu Adı",
          positionsPlaceholder: "Pozisyonlar",
          confirmModalTitle: "Emin misiniz?",
          confirmModalMessage:
            "Geri giderseniz, maç ayarlarınız kaybolur. Geri gitmek istiyor musun?",
          confirmModalYes: "Evet",
          confirmModalNo: "Hayır",
          backButton: "Geri",
          nextStepButton: "Sonraki adım",
          secondStepLabel: "İkinci adım",
          secondStepDescription: "Eşleştirmeleri ayarla",
          matchesTitle: "Eşleştirmeler",
          matchesDescription:
            "Bazen iki oyuncu güç dengesizliğine neden olabilir. Onları aynı takıma koymak istemeyebilirsiniz. Bu bölümde iki oyuncuyu eşleştirebilirsiniz. Takımlar oluşturulurken bu iki oyuncu aynı takımda olamaz. İhtiyacınız yoksa bu kısmı atlayabilirsiniz.",
          player1Placeholder: "Oyuncu 1",
          player2Placeholder: "Oyuncu 2",
          confirmButton: "Onayla",
          finalStepLabel: "Son Adım",
          finalStepDescription: "İnceleme",
          downloadTitle: "İndir",
          downloadDescription:
            "Takım dosyasını (.JSON) aşağıdan indirebilirsiniz. Lütfen bu dosyayı daha sonraki oturumlar için kullanmak üzere kaydedin. Her oturumdan önce temel dosyayı düzenleyebilirsiniz.",
          downloadButton: "İndir",
          goToHomePageButton: "Ana Sayfaya Git",
          reviewTeamDataTitle: "Takım Verilerinizi Gözden Geçirin",
          createSessionButton: "Oturum Oluştur",
          dataLoadErrorTitle: "Veriler yüklenemedi.",
          dataLoadErrorMessage:
            "Lütfen takım verilerinizi kontrol edip tekrar deneyin.",
          createSessionErrorTitle: "Oturum oluşturulurken hata oluştu.",
          createSessionErrorMessage:
            "Lütfen internet bağlantınızı kontrol edip tekrar deneyin.",
          joinButton: "Katıl",
          teamATitle: "Takım A",
          teamBTitle: "Takım B",
          copiedText: "Kopyalandı",
          copyAsText: "Metin olarak kopyala",
          invite: "Davet Et",
          copied: "Kopyalandı",
          copy: "Kopyala",
          confirm: "Onayla",
          cancel: "İptal",
          leaveSession: "Oturumu Terk Et",
          chat: "Sohbet",
          users: "Kullanıcılar",
          pick: "Seç",
          export: "Dışa Aktar",
          setTeamACaptain: "A Takım Kaptanı Olarak Ata",
          setTeamBCaptain: "B Takım Kaptanı Olarak Ata",
          disconnect: "Bağlantıyı Kes",
          confirmUserDisconnectTitle: "Kullanıcı Bağlantısını Kesmeyi Onayla",
          confirmUserDisconnectMessage:
            "{{username}} kullanıcısının bağlantısını kesmek istediğinizden emin misiniz?",
          confirmExitTitle: "Çıkışı Onayla",
          confirmExitMessage: "Oturumu terk etmek istediğinizden emin misiniz?",
          regularModeTitle: "Takımlar Tamamlanıyor",
          matchesModeTitle: "Eşleştirmeler Seçiliyor",
          matchesModeDescription: {
            desc1: "Maçlar modu açıklaması satır 1",
            desc2: "Maçlar modu açıklaması satır 2",
          },
          yourTurnMessage: "{{username}}, sıra sende kaptan.",
          yourTurnNoCaptain:
            "{{turn}} sıra sizde. Oyuncu seçimi yapmanız için bir kaptan atanmalıdır.",
          yourTurnErrorMessage: "Bir hata oluştu.",
          endedByModTitle: "Oturum Sonlandırıldı",
          endedByModMessage:
            "Moderatör tarafından oturum sonlandırıldı. Ana sayfaya yönlendiriliyorsunuz.",
          invalidSessionTitle: "Geçersiz Oturum",
          invalidSessionMessage: "Oturum geçersiz veya sona erdi.",
          kickedByModTitle: "Oturumdan Atıldınız.",
          kickedByModDesc: "Moderatör tarafından oturumdan atıldınız.",
          internalErrorTitle: "Hata",
          internalErrorMessage: "Bir hata oluştu.",
          beReadyTitle: "Oturum Henüz Başlatılmadı",
          noCaptainYet: "Henüz kaptan atanmadı",
          noPlayerSelected: "Henüz oyuncu seçilmedi",
          moveTo: "{{username}}'i taşı ",
          modBadge: "Mod",
          captainABadge: "A (C)",
          captainBBadge: "B (C)",
          poolTitle: "Oyuncu Havuzu",
          startTheSession: "Oturumu Başlat",
          teamFileDescription:
            "Oyuncular dosyası, oturumu başlattığınızda bilgisayarın veya kaptanların seçimlerini yapacağı bir dosyadır.Bu dosya oyuncuları ve ilgili bilgileri içerir. Eğer daha önceden oluşturduğunuz bir dosya yoksa bu bölümü boş bırakıp, bir sonraki aşamada oyuncularınızı oluşturabilirsiniz.",
          player1: "Oyuncu 1",
          player2: "Oyuncu 2",
          fileName: "Dosya adı",
          notProducedErrorTitle: "İşleminiz Gerçekleştirilemedi",
          notProducedErrorDescription:
            "Taşımak istediğiniz oyuncu eşleştirme listesinde yer almaktadır. Eşleştirme oyuncuları seçilmeden taşıma işlemi gerçekleştirilemez.",
          generateByComputer: "Bilgisayar ile Oluştur",
          regenerate: "Tekrar Oluştur",
          designedAndDeveloped:
            "<0>tscburak</0> tarafından tasarlanıp geliştirildi.",
          howItWorksTitle: "Nasıl Çalışır?",
          howItWorksDesc: [
            'aspaskes ile takımlarınızı ister çevrim içi oturumlar ile kaptanlar belirleyerek oluşturun, isterseniz oyuncuların gücü esas alınarak bilgisayar destekli takımlar oluşturun. Daha önceden aspaskes üzerinden oluşturduğunuz takım dosyanız varsa bu dosyayı yükleyerek, yoksa dosya alanını boş bırakarak <0>Devam Et</0> butonuna basın. Çıkan ekranda varsa takım dosyasını düzenleyebilir, yoksa oluşturup bir sonraki kullanımlar için bilgisayarınıza indirebilirsiniz.',
            'Buradaki oyunculardan iki takım oluşturmak için isterseniz <0>Bilgisayar ile Oluştur</0> isterseniz de <0>Oturum Oluştur</0> seçeneğinden yararlanabilirsiniz.',
            "<0>Bilgisayar ile oluştur:</0> Oyuncularınızı oluştururken verdiğiniz genel güç değerlerinden yararlanarak dengeli bir takım oluşturmayı hedefler.",
            "<0>Oturum oluştur:</0> Çevrim içi bir şekilde, kaptanlar belirleyerek arkadaşlarınızla takımlarınızı oluşturmayı hedefler."
          ],
          aspaskesSlug:"aspaskes - Adım At, Seç, Oyna!",
          languages:"Diller",
          optional: "Opsiyonel",
          nextWithoutFile: "Takım Dosyası Olmadan Devam Et",
          fileCannotResolvedTitle: "Dosya Hatası",
          fileCannotResolvedDescription: "Takım dosyası çözümlenemedi. Lütfen dosyanın doğruluğundan emin olun.",
          addMatch: "Eşleştirme Ekle",
          addPlayer: "Oyuncu Ekle",

        },
      },
    },
  });

export default i18n;

export const lngs = {
  en: { nativeName: "English" },
  tr: { nativeName: "Türkçe" },
};
