import { Flex, Group, Text, Title } from "@mantine/core";
import classes from "../styles/player-card.module.css";

export default function PlayerCard({
  props,
  selected = false,
  onClick,
  direction,
  left,
}) {
  let body = <></>;

  switch (direction ?? "horizontal") {
    case "horizontal":
      body = (
        <Flex
          gap={"xs"}
          onClick={onClick}
          classNames={{
            root: selected ? classes.selectedCardRoot : classes.cardRoot,
          }}
          justify={"space-between"}
        >
          <Group wrap="nowrap">
            {left}
            <Text ta={"start"} size="sm" fw={700}>
              {props.name}
            </Text>
          </Group>

          {props.overall && (
            <Text c={`APKSOverallColors.${props.overall - 1}`}>
              {props.overall}
            </Text>
          )}
        </Flex>
      );
      break;
    case "vertical":
      body = (
        <Flex
          direction={"column"}
          gap={"xs"}
          justify="center"
          align="center"
          onClick={onClick}
          classNames={{
            root: selected ? classes.selectedCardRoot : classes.cardRoot,
          }}
        >
          <Text truncate size="sm" fw={700}>
            {props.name}
          </Text>
          
          {props.overall && (
            <Text c={`APKSOverallColors.${props.overall - 1}`}>
              {props.overall}
            </Text>
          )}
        </Flex>
      );
      break;
    default:
      break;
  }

  return body;
}
