/* eslint-disable jsx-a11y/anchor-has-content */
import {
  ActionIcon,
  Button,
  Center,
  FileInput,
  Flex,
  Grid,
  Group,
  Image,
  Menu,
  Paper,
  Popover,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import {
  IconInfoSmall,
  IconQuestionMark,
  IconSettings,
  IconTrash,
} from "@tabler/icons-react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { CodeError } from "../error";
import { lngs } from "../i18n";
import logo from "../images/logo.svg";

export default function Home() {
  const [file, setFile] = useState();
  const [username, setUsername] = useState("");
  const [data, setData] = useState();
  const [createLoading] = useState(false);
  const { t, i18n } = useTranslation();

  async function fileHandler(file) {
    if (!file) {
      return;
    }
    if (file.type === "application/json") {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const jsonData = JSON.parse(e.target.result);
          jsonData.pool.forEach((player) => {
            if (
              typeof player.name !== "string" ||
              typeof player.overall !== "number" ||
              typeof player.id !== "number"
            ) {
              throw new CodeError("Not Acceptable", 406);
            }
          });
          setData(jsonData);
        } catch (error) {
          notifications.show({
            title: t("fileCannotResolvedTitle"),
            message: t("fileCannotResolvedDescription"),
            color: "red",
          });
          setFile(null);
          setData(null)
          return;
        }
      };
      setFile(file);
      reader.readAsText(file);
    } else {
      notifications.show({
        title: t("fileCannotResolvedTitle"),
        message: t("fileCannotResolvedDescription"),
        color: "red",
      });
    }
  }

  async function createSessionHandler() {
    window.sessionStorage.setItem(
      "aspaskes.beg.data",
      JSON.stringify({
        ...data,
        username,
      })
    );
    if (file)
      window.sessionStorage.setItem(
        "aspaskes.beg.fn",
        file.name.replace(/.json$/g, "")
      );
    window.location.hash = "/edit-team";
  }

  return (
    <Center bg={"APKSGray.0"} h={"100vh"} mb={"auto"} p={"xl"}>
      <Stack>
        <Paper w={400} p={"xl"} shadow="xl">
          <Stack pb={"xl"} gap={0}>
            <Flex>
              <Group gap={"xs"} ml={"auto"}>
                <Popover
                  width={300}
                  position="bottom-end"
                  withArrow
                  shadow="md"
                >
                  <Popover.Target>
                    <ActionIcon
                      color="gray"
                      size={"xs"}
                      radius={"xl"}
                      variant="outline"
                    >
                      <IconInfoSmall />
                    </ActionIcon>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <Stack size="xs">
                      <Text fw={700} size="sm">
                        {t("howItWorksTitle")}
                      </Text>
                      {t("howItWorksDesc", { returnObjects: true }).map(
                        (text) => (
                          <Text size="xs">
                            <Trans components={[<b />]}>{text}</Trans>
                          </Text>
                        )
                      )}
                    </Stack>
                  </Popover.Dropdown>
                </Popover>
                <Menu width={300} position="bottom-end" withArrow shadow="md">
                  <Menu.Target>
                    <ActionIcon
                      p={0}
                      color="gray"
                      size={"sm"}
                      radius={"xl"}
                      variant="subtle"
                    >
                      <IconSettings stroke={1.1} />
                    </ActionIcon>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Label>{t("languages")}</Menu.Label>
                    {Object.keys(lngs).map((lng) => (
                      <Menu.Item
                        c={i18n.resolvedLanguage === lng ? "APKSBlue" : ""}
                        bg={i18n.resolvedLanguage === lng ? "APKSBlue.1" : ""}
                        onClick={() => i18n.changeLanguage(lng)}
                      >
                        <Text size="xs">{lngs[lng].nativeName}</Text>
                      </Menu.Item>
                    ))}
                  </Menu.Dropdown>
                </Menu>
              </Group>
            </Flex>
            <Image
              pt={"lg"}
              pb={"lg"}
              style={{ alignSelf: "center" }}
              src={logo}
              w={150}
            ></Image>
            {/* <TextInput
            withAsterisk
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            accept=".json"
            label={t("username")}
            placeholder={t("enterUsername")}
          /> */}
            <Grid grow align="end">
              <Grid.Col span={10}>
                <FileInput
                  description={`(${t("optional")})`}
                  value={file}
                  onChange={fileHandler}
                  accept=".json"
                  label={
                    <Group gap={5}>
                      <Text size="sm" fw={600}>
                        {t("teamFile")}
                      </Text>
                      <Tooltip
                        multiline
                        maw={400}
                        label={t("teamFileDescription")}
                        events={{ hover: true, focus: true, touch: true }}
                      >
                        <ActionIcon
                          size={"xs"}
                          radius={"xl"}
                          variant="outline"
                          color="gray"
                        >
                          <IconQuestionMark />
                        </ActionIcon>
                      </Tooltip>
                    </Group>
                  }
                  // description={t("teamFileDescription")}
                  placeholder={t("uploadTeamFile")}
                />
              </Grid.Col>
              {file && (
                <Grid.Col span={2}>
                  <ActionIcon
                    onClick={() => {
                      setFile(null);
                      setData(null);
                    }}
                    mb={1}
                    size={"lg"}
                    color="red"
                  >
                    <IconTrash />
                  </ActionIcon>
                </Grid.Col>
              )}
            </Grid>
          </Stack>
          <Button
            mb={"xl"}
            fullWidth
            loading={createLoading}
            onClick={createSessionHandler}
            // disabled={username.length === 0}
          >
            {file ? t("next") : t("nextWithoutFile")}
          </Button>
        </Paper>
        <Text size="sm" ta={"center"} fs={"italic"} c={"dimmed"}>
          <Trans
            i18nKey={"designedAndDeveloped"}
            components={[
              <a style={{ color: "black" }} href="https://tscburak.dev" />,
            ]}
          ></Trans>
        </Text>
      </Stack>
    </Center>
  );
}
