import {
  ActionIcon,
  Button,
  Center,
  Flex,
  Group,
  Menu,
  Paper,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../api/api";
import { notifications } from "@mantine/notifications";
import { useTranslation } from "react-i18next"; // Added this line
import { IconSettings } from "@tabler/icons-react";
import { lngs } from "../i18n";

export default function Join() {
  const { t, i18n } = useTranslation(); // Added this line
  const [username, setUsername] = useState("");
  const { sessionUUID } = useParams();
  const api = new API();

  async function joinSessionHandler() {
    await api
      .joinSession(sessionUUID, username)
      .then((response) => {
        Cookies.set("jwt", response.data.token, { sameSite: "strict" });
        window.location.hash = "/playground/" + sessionUUID;
      })
      .catch((err) => {
        console.log(err);
        if (err.status === 404) {
          notifications.show({
            title: t("invalidSessionTitle"),
            message: t("invalidSessionMessage"),
            autoClose: 5000,
            color: "red",
          });
          window.location.hash = "";
        }
      });
  }

  useEffect(() => {
    if (Cookies.get("jwt")) {
      window.location.hash = `#playground/${sessionUUID}`;
    }
  }, []);

  return (
    <Center bg={"APKSGray.0"} h={"100vh"} mb={"auto"}>
      <Paper p={"xl"} shadow="xl">
        <Stack>
          <Flex>
            <Group gap={"xs"} ml={"auto"}>
              <Menu width={300} position="bottom-end" withArrow shadow="md">
                <Menu.Target>
                  <ActionIcon
                    p={0}
                    color="gray"
                    size={"sm"}
                    radius={"xl"}
                    variant="subtle"
                  >
                    <IconSettings stroke={1.1} />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Label>{t("languages")}</Menu.Label>
                  {Object.keys(lngs).map((lng) => (
                    <Menu.Item
                      c={i18n.resolvedLanguage === lng ? "APKSBlue" : ""}
                      bg={i18n.resolvedLanguage === lng ? "APKSBlue.1" : ""}
                      onClick={() => i18n.changeLanguage(lng)}
                    >
                      <Text size="xs">{lngs[lng].nativeName}</Text>
                    </Menu.Item>
                  ))}
                </Menu.Dropdown>
              </Menu>
            </Group>
          </Flex>
          <TextInput
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            accept=".json"
            label={t("username")}
            placeholder={t("enterUsername")}
          />
          <Button onClick={joinSessionHandler} disabled={username.length === 0}>
            {t("joinButton")}
          </Button>
        </Stack>
      </Paper>
    </Center>
  );
}
