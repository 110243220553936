import { Button, CopyButton, Stack } from "@mantine/core";
import { IconCheck, IconCopy } from "@tabler/icons-react";
import { useTranslation } from "react-i18next"; // Added this line

export default function ExportView({ teama, teamb }) {
  const { t } = useTranslation(); // Added this line

  const text =
    `${t("teamATitle")}\n\n` +
    teama.map((player) => player.name).join("\n") +
    "\n\n----------\n\n" +
    `${t("teamBTitle")}\n\n` +
    teamb.map((player) => player.name).join("\n");

  return (
    <Stack>
      <CopyButton value={text}>
        {({ copied, copy }) => (
          <Button
            leftSection={copied ? <IconCheck /> : <IconCopy />}
            color={copied ? "teal" : "APKSBlue"}
            onClick={copy}
          >
            {copied ? t("copiedText") : t("copyAsText")}
          </Button>
        )}
      </CopyButton>
    </Stack>
  );
}
