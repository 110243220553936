/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import Playground from "./pages/playground";
import Home from "./pages/home";
import Join from "./pages/join";
import CreateTeam from "./pages/create-team";
import EditTeam from "./pages/edit-team";
import GenerateTeam from "./pages/generate";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" Component={Home}/>
        <Route path="/playground/:sessionUUID" Component={Playground} />
        <Route path="/join/:sessionUUID" Component={Join} />
        <Route path="/create-team" Component={CreateTeam} />
        <Route path="/edit-team" Component={EditTeam} />
        <Route path="/generate" Component={GenerateTeam} />
      </Routes>
    </HashRouter>
  );
}

export default App;
